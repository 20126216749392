import { observer, inject } from 'mobx-react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber'; //

import * as THREE from 'three';
import Tetr from './Tetr';

const ClippingPlane = ({ x, y, z }) => {
  const { gl } = useThree();
  const planeX = new THREE.Plane(new THREE.Vector3(-1, 0, 0), x);
  const planeY = new THREE.Plane(new THREE.Vector3(0, -1, 0), y);
  const planeZ = new THREE.Plane(new THREE.Vector3(0, 0, -1), z);
  gl.clippingPlanes = [planeX, planeY, planeZ]; // , planeY, planeZ
  gl.localClippingEnabled = true;
  return <></>;
};

const CanvasResult = ({ spherStore, sliders }) => (
  <Canvas camera={{ position: [0, 0, 1.2] }}>
    <ambientLight intensity={0.5} />
    {/* <spotLight position={[10, 10, 10]} angle={0.3} penumbra={1} /> */}
    <PerspectiveCamera makedefault position={[-5, 1, 5]} />
    <group>
      {spherStore.currElements.map((elem, index) => (
        <Tetr
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          points={[
            spherStore.currPoints[elem[0]],
            spherStore.currPoints[elem[1]],
            spherStore.currPoints[elem[2]],
            spherStore.currPoints[elem[3]],
          ]}
          color={[
            spherStore.currColors[elem[0]],
            spherStore.currColors[elem[1]],
            spherStore.currColors[elem[2]],
            spherStore.currColors[elem[3]],
          ]}
          minValue={spherStore.minCurrColor}
          maxValue={spherStore.maxCurrColor}
        />
      ))}
    </group>

    <ClippingPlane x={sliders.x} y={sliders.y} z={sliders.z} />
    <OrbitControls />
  </Canvas>
);
export default inject(['spherStore'])(observer(CanvasResult));
