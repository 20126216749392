// import React from 'react';
// import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import Link from react-router-dom
import Button from '@mui/material/Button'; // Import Button specifically
import { useCallback } from 'react';

// import Header from '../components/Lending/Header';
// import Footer from '../components/Lending/Footer';
import Animated3D from '../components/3D/Animated3D';
import styles from '../assets/styles/Components/lending.module.scss';
// import Product1 from '../assets/images/Pic1.jpg';
// import Product3 from '../assets/images/Pic3.jpg';
import Logo from '../components/Logo/Logo';
// import Team from '../components/Lending/Team/Team';
// import FormFeedback from '../components/Lending/Form/FormFeedback';
// import QA from '../components/Lending/QA/QA';
// import HRBlock from '../components/Lending/HRBlock/HRBlock';

const LendingPage = () => {
  const navigate = useNavigate(); // Move the useNavigate hook inside the component
  const handleOpenApp = useCallback((e) => {
    e.preventDefault();
    navigate('app');
  }, []);
  // Wrap the callback in the useCallback hook and move it inside the component

  return (
    <div className={styles.wrapper} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <div className={styles.logo__wrapper}>
        <div className={styles.logo} to='/'>
          <Logo width='10vw' />
        </div>
        <p className={styles.logo__text}>Viabio</p>
      </div>
      <div className={styles.buttonsWrapper} >
        <a href="https://viabio.site" target="_blank" rel="noopener noreferrer">
          <Button variant="contained" color="primary" className={styles.button}>
            About Viabio
          </Button>
        </a>
        <Button
          color='primary'
          onClick={handleOpenApp}
          variant='contained'
          className={styles.button}
        >
          Test software
        </Button>
      </div>
      <div className={styles.canvas_new}>
        <Animated3D />
      </div>
    </div>
  );
  
};



export default LendingPage;