const Logo = ({ width, height, className }) => (
  <svg viewBox='0 0 109.378 109.178' width={width} height={height}>
    <defs>
      <filter
        id='a'
        width='1.032'
        height='1.04'
        x='-.016'
        y='-.016'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodColor='#000' floodOpacity='.498' result='flood' />
        <feComposite
          in='flood'
          in2='SourceGraphic'
          operator='in'
          result='composite1'
        />
        <feGaussianBlur in='composite1' result='blur' stdDeviation='.7' />
        <feOffset dy='.789' result='offset' />
        <feComposite in='SourceGraphic' in2='offset' result='composite2' />
      </filter>
    </defs>
    <g
      className={className}
      filter='url(#a)'
      transform='translate(-.878 -98.508)'
    >
      <circle cx='27.187' cy='113.499' r='6.25' />
      <circle cx='63.231' cy='113.562' r='6.25' />
      <circle cx='94.97' cy='124.696' r='6.25' />
      <circle cx='95.115' cy='160.845' r='6.25' />
      <circle cx='15.876' cy='144.65' r='6.25' />
      <circle cx='15.731' cy='180.654' r='6.25' />
      <circle cx='47.687' cy='192.077' r='6.25' />
      <circle cx='83.981' cy='191.933' r='6.25' />
      <circle cx='14.237' cy='123.057' r='4.25' />
      <circle cx='76.028' cy='122.96' r='4.25' />
      <circle cx='85.571' cy='111.827' r='4.25' />
      <circle cx='85.427' cy='173.424' r='4.25' />
      <circle cx='96.85' cy='182.245' r='4.25' />
      <circle cx='35.107' cy='182.389' r='4.25' />
      <circle cx='25.564' cy='193.379' r='4.25' />
      <circle cx='25.419' cy='131.781' r='4.25' />
      <circle cx='12.041' cy='162.793' r='7.5' />
      <circle cx='99.019' cy='142.481' r='7.5' />
      <circle cx='44.94' cy='109.513' r='7.5' />
      <circle cx='66.051' cy='195.837' r='7.5' />
      <circle cx='36.119' cy='124.924' r='2.5' />
      <circle cx='7.056' cy='133.66' r='2.5' />
      <circle cx='82.968' cy='133.805' r='2.5' />
      <circle cx='74.727' cy='180.305' r='2.5' />
      <circle cx='28.167' cy='171.689' r='2.5' />
      <circle cx='36.264' cy='200.753' r='2.5' />
      <circle cx='74.684' cy='104.537' r='2.5' />
      <circle cx='104.079' cy='171.689' r='2.5' />
      <circle cx='25.419' cy='163.474' r='1.75' />
      <circle cx='4.308' cy='141.902' r='1.75' />
      <circle cx='44.506' cy='122.671' r='1.75' />
      <circle cx='106.827' cy='163.592' r='1.75' />
      <circle cx='66.695' cy='183.116' r='1.75' />
      <circle cx='85.477' cy='142.018' r='1.75' />
      <circle cx='66.664' cy='101.938' r='1.75' />
      <circle cx='44.374' cy='203.467' r='1.75' />
    </g>
  </svg>
);
export default Logo;
