import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// eslint-disable-next-line import/no-mutable-exports
let theme = createTheme({
  palette: {
    primary: { main: '#b60cf2', dark: '#6600cd', light: '#e80ce3' },
    acent: { main: '#6600cd', dark: '#390cf2', light: '#b60cf2' },
  },
  typography: {
    button: {
      fontFamily: '"Quicksand", sans-serif',
      textTransform: 'initial',
      fontWeight: 700,
      fontSize: '1rem',
      color: '#fff',
    },
    body1: {
      fontFamily: '"Quicksand", sans-serif',
    },
    fontFamily: '"Quicksand", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 30,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          // selected and (selected + hover) states

          '&& .Mui-selected, && .Mui-selected:hover': {
            // borderRadius: '20px',
            backgroundColor: '#E5EFFF',
            '&, & .MuiListItemIcon-root': {
              color: '#AD3AF0',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            borderRadius: '10px',
            backgroundColor: 'rgba(230, 239, 255, 0.25)',
            '&, & .MuiListItemIcon-root': {
              color: '#AD3AF0',
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid #AD3AF0', // Solid underline on hover
          },
          '&:after': {
            borderBottom: '2px solid #AD3AF0', // Solid underline on focus
          },
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
