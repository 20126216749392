import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Input, InputLabel } from '@mui/material';

import '../../../assets/styles/Components/formSpheroid.scss';

const FormSpher1 = ({ formObj, onSubmit }) => {
  const [val, setVal] = useState(formObj);
  const navigate = useNavigate();
  const handleChange = (e) => {
    const newObj = { ...val[e.target.dataset.type], value: e.target.value };
    setVal((prevState) => ({
      ...prevState,
      [e.target.dataset.type]: newObj,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(val);
    navigate('/app/responses/1233');
  };

  return (
    <form className='formNew' onSubmit={handleSubmit}>
      <Grid container columnSpacing={5} rowSpacing={2}>
        {Object.keys(val).map((elem) => (
          <Grid item xs={12} sm={6} md={4}>
            <div className='formControl'>
              <InputLabel> {val[elem].caption}</InputLabel>

              <Input
                key={elem}
                inputProps={{
                  'data-type': elem,
                }}
                onChange={handleChange}
                type='number'
                value={val[elem].value}
              />
            </div>
          </Grid>
        ))}
        <Grid className='btnGrid' item>
          <Button type='submit' variant='outlined' disabled>
            Results
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormSpher1;
