import { Link, Stack, Typography } from '@mui/material';
import Animated3D from '../components/3D/Animated3D';
import styles from '../assets/styles/Components/messagePage.module.scss';

const MessagePage = ({ id }) => (
  <Stack
    className={styles.wrapper}
    direction='row'
    justifyContent='center'
    alignItems='center'
    spacing={4}
  >
    <Stack
      direction='column'
      justifyContent='space-around'
      alignItems='center'
      spacing={2}
    >
      <Typography variant='h2' component='h1'>
        Your ID is:
      </Typography>
      <Typography variant='h1' component='h2'>
        {id}
      </Typography>
      <Typography variant='h3'>Please, remember it</Typography>
      <Stack direction='row' spacing={2}>
        <Typography variant='h4'>You can check result</Typography>
        <Typography variant='h4'>
          <Link href='/app/responses'>here</Link>
        </Typography>
      </Stack>
    </Stack>
    <div className={styles.canvas}>
      <Animated3D />
    </div>
  </Stack>
);
export default MessagePage;
