import { Canvas } from '@react-three/fiber';
import { useMemo } from 'react';
import ModelStl from './ModelStl';

const Animated3D = () => {
  const canv = useMemo(
    () => (
      <Canvas antialias={false} pixelRatio={window.devicePixelRatio * 0.5}>
        <ModelStl animate url='/gardner_noise.stl' />
        <directionalLight />
      </Canvas>
    ),
    []
  );
  return <>{canv}</>;
};
export default Animated3D;
