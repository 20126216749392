import { Box, CssBaseline } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from '../components/MainComponents/Header/Header';
import SideBar from '../components/MainComponents/SideBar/SideBar';
import styles from '../assets/styles/Components/mainPage.module.scss';
import ModallAbout from '../components/MainComponents/ModalAbout/ModalAbout';

const MainPage = () => {
  const location = useLocation();
  const [helpOpen, setHelpOpen] = useState(null);
  const [confirm, setConfirm] = useState(null);

  useEffect(() => {
    let showLocal = JSON.parse(localStorage.getItem('confirm-show-help'));

    if (showLocal === null) {
      localStorage.setItem('confirm-show-help', JSON.stringify(false));
      showLocal = false;
    }

    setConfirm(showLocal);
    setHelpOpen(!showLocal);
  }, []);

  const setConfirmation = () => {
    localStorage.setItem('confirm-show-help', JSON.stringify(!confirm));
    setConfirm(!confirm);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  useEffect(() => {}, [location]);
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header className={styles.header} location={location} />
      <SideBar className={styles.aside} />
      <main className={styles.main}>
        <Outlet />
      </main>
      <ModallAbout
        isOpen={helpOpen}
        handleClose={handleHelpClose}
        confirmation={{ confirm, changeConfirm: setConfirmation }}
      />
    </Box>
  );
};

export default MainPage;
