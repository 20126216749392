import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { useState } from 'react';
import styles from '../../../assets/styles/Components/modalAbout.module.scss';

const ModallAbout = ({ isOpen, handleClose, confirmation }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <DialogTitle>
        <h2 className={styles.h2}>Guidance</h2>
      </DialogTitle>
      <DialogContent>
        <p className={styles.greet}>
          Welcome to our software platform designed to model and optimize the
          viability of bioconstructions.
        </p>
        <p>
          Follow these steps to make the most of the capabilities of our
          advanced tool:
        </p>

        <Stepper activeStep={activeStep} orientation='vertical'>
          <Step>
            <StepLabel>
              <h3 className={styles.stepLabel}>
                Creating a Bioconstruction Model First, you need to create a
                model of a bioconstruction
              </h3>
            </StepLabel>

            <StepContent>
              You have two options:
              <ol>
                <li>
                  Preloading CAD:{' '}
                  <a href='https://forms.gle/R3NkhPQjJ1ozRSuX7' target='blank'>
                    Load
                  </a>{' '}
                  an existing CAD object in STL format.
                </li>
                <li>
                  Graphical Interface: Use our userfriendly graphical interface
                  to design the geometry of the bioconstruction{' '}
                  <em>
                    <b>(currently in progress)</b>
                  </em>
                  .
                </li>
              </ol>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>
              <h3 className={styles.stepLabel}>
                Setting up Bioprinting Parameters
              </h3>
            </StepLabel>

            <StepContent>
              <p>
                Once you have finalized the geometric aspects of your project,
                it&apos;s time to adjust the bioprinting parameters. At this
                stage, it&apos;s necessary to specify important details such as
                timestep, spheroid volume, consumption rate, cell type, and
                more.
              </p>
              <p>
                You can also insert any comments, suggestions, or additional
                notes before starting the calculation. This information is
                crucial for a more detailed understanding of your requirements.
              </p>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>
              <h3 className={styles.stepLabel}>Viewing Results</h3>
            </StepLabel>

            <StepContent>
              <p>
                After configuring the necessary parameters and loading the
                geometric model of the bioconstruction, simply click the
                &ldquo;Result&ldquo; button. Wait for our platform to perform
                calculations. As a result, you will receive a map showing the
                distribution of oxygen concentration in the bioconstruction and
                highlighted potential areas of necrosis, along with related
                characteristics.
              </p>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    variant='contained'
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Continue
                  </Button>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>

          <Step>
            <StepLabel>
              <h3 className={styles.stepLabel}>
                Optimization Recommendations <em>(currently in progress)</em>
              </h3>
            </StepLabel>

            <StepContent>
              <p>
                Following an analysis of the weaknesses in the bioconstruction,
                our platform will provide recommendations for optimizing the
                geometry and bioprinting parameters. These recommendations aim
                to enhance the viability of the final bioconstruction.
              </p>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        </Stepper>
      </DialogContent>

      <DialogActions>
        <div className={styles.actions}>
          {confirmation ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmation.confirm}
                  onChange={confirmation.changeConfirm}
                />
              }
              label="don't show again"
            />
          ) : (
            <></>
          )}

          <Button
            variant='contained'
            className={styles.button}
            onClick={handleClose}
          >
            Ready to go
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ModallAbout;
