import { Slider, Typography, Grid } from '@mui/material';
import { observer, inject } from 'mobx-react';
import { useState } from 'react';
import CanvasResult from '../../3D/CanvasResult';
import styles from '../../../assets/styles/Components/result.module.scss';

const ResultComponent = ({ spherStore }) => {
  const [sliders, setSliders] = useState({ x: 1, y: 1, z: 1 });

  const handleChange = (e) => {
    setSliders((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangeTime = (e, value) => {
    spherStore.changeCurr(value);
  };
  return (
    <Grid className={styles.container} container spacing={2}>
      <Grid className={styles.result} item xs={12} xl={8}>
        <CanvasResult sliders={sliders} />
      </Grid>
      <Grid item xs={12} xl={4} p={4}>
        <div className={styles.sliders}>
          <Typography>Time</Typography>
          <Slider
            name='time'
            track={false}
            aria-labelledby='track-false-slider'
            defaultValue={0}
            marks
            step={1}
            min={0}
            max={spherStore.currTimeLength}
            valueLabelDisplay='on'
            onChange={handleChangeTime}
          />

          <Typography>X</Typography>
          <Slider
            track={false}
            aria-labelledby='track-false-slider'
            defaultValue={1}
            marks
            step={0.1}
            min={-1}
            max={1}
            name='x'
            valueLabelDisplay='on'
            onChange={handleChange}
          />

          <Typography>Y</Typography>
          <Slider
            track={false}
            aria-labelledby='track-false-slider'
            defaultValue={1}
            marks
            step={0.1}
            min={-1}
            max={1}
            name='y'
            valueLabelDisplay='on'
            onChange={handleChange}
          />

          <Typography>Z</Typography>
          <Slider
            track={false}
            aria-labelledby='track-false-slider'
            defaultValue={0}
            marks
            step={0.1}
            min={-1}
            max={1}
            name='z'
            valueLabelDisplay='on'
            onChange={handleChange}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default inject(['spherStore'])(observer(ResultComponent));
