import { useFrame, useThree } from '@react-three/fiber';

import { useRef, useEffect, useState } from 'react';
// import { BufferGeometry } from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';

const ModelStl = ({ url, animate = false }) => {
  const [geometry, setGeometry] = useState();
  //  const geom = useLoader(STLLoader, url);

  useEffect(() => {
    const stlLoader = new STLLoader();
    stlLoader.load(url, (geo) => {
      setGeometry(geo);
    });
  }, []);

  const ref = useRef();
  const { camera } = useThree();
  useEffect(() => {
    camera.lookAt(ref.current.position);
  }, []);

  useFrame(({ clock }) => {
    if (animate) {
      const a = clock.getElapsedTime();
      ref.current.rotation.y = a / 5;
      ref.current.rotation.x = a / 5;
      ref.current.rotation.z = a / 5;
    }
  });

  return (
    <>
      <mesh ref={ref} scale={4} geometry={geometry}>
        {/* <primitive object={geom} attach='geometry' /> */}
        <meshPhongMaterial color='#b60cf2' specular='#cef03a' wireframe />
      </mesh>
      <ambientLight />
      <pointLight position={[15, 15, 15]} />
    </>
  );
};
export default ModelStl;
