import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import styles from '../assets/styles/Components/listIDS.module.scss';

const ids = ['result example'];

const ListIds = () => (
  <div className={styles.wrapper}>
    <Typography variant='h1' component='h1'>
      Your responses:
    </Typography>
    <List>
      {ids.map((elem) => (
        <ListItem key={elem}>
          <ListItemButton component={Link} to='result'>
            <ListItemText>{elem}</ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
    <Typography variant='h3'>Or</Typography>
    <Stack direction='row' spacing={2} alignItems='center'>
      <Typography variant='h2' component='h2'>
        Enter your ID:
      </Typography>

      <TextField type='number' />
      <Button type='submit' variant='outlined'>
        Check
      </Button>
    </Stack>
  </div>
);
export default ListIds;
