import Usackis from '../../../assets/images/Usackis.png';
// import Kat from '../../../assets/images/Katherine.jpg';
// import Kirillov from '../../../assets/images/Kirillov.jpg';
// import Annikov from '../../../assets/images/Annikov.jpg';
import Akimov from '../../../assets/images/Akimov.jpg';
import Evija from '../../../assets/images/Evija.jpg';
import Nicolay from '../../../assets/images/Nicolay.jpg';

const employers = [
  {
    name: 'Otomārs UŠACKIS',
    position: 'Chief Executive Officer',
    photo: Usackis,
    link: 'https://www.linkedin.com/in/otomars-usackis-b4209637/',
    mail: 'otomars@viabio.tech',
  },

  {
    name: 'Evija VASCENKO',
    position: 'Venture Engagement Partner',
    photo: Evija,
    link: 'https://www.linkedin.com/in/evija-vascenko/',
    mail: 'evija@viabio.tech',
  },

  {
    name: 'Nikolay MALUKHIN',
    position: 'Chief Technical Officer & Co-Founder',
    photo: Nicolay,
    link: 'https://www.linkedin.com/in/nikolaymalukhin/',
    mail: 'nikolay@viabio.tech',
  },

  /* {
    name: 'Katherine VILINSKI-MAZUR',
    position: 'Chief Engineer (R&D) & Founder CEO',
    photo: Kat,
  }, */

  /* {
    name: 'Bogdan KIRILLOV',
    position: 'Senior Software Developer & Co-Founder CTO',
    photo: Kirillov,
  }, */

  {
    name: 'Michael AKIMOV',
    position: 'Science Engagement Partner, Non-Executive Director',
    photo: Akimov,
    link: 'https://www.linkedin.com/in/michael-k-akimov-5a5489/',
    mail: 'michael@viabio.tech',
  },

  /* {
    name: 'Maxim ANNIKOV',
    position: 'Customer Development & Marketing CMO',
    photo: Annikov,
  }, */
];

export default employers;
