import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { IconButton } from '@mui/material';
import * as classnames from 'classnames';
import styles from '../../../assets/styles/Components/team.module.scss';

const PrevArrow = ({ onClick }) => (
  <IconButton
    aria-label='arrowlefticon'
    onClick={onClick}
    sx={{ color: 'white', position: 'absolute' }}
    className={classnames(styles.but, styles.but_left)}
  >
    <ArrowLeftIcon fontSize='large' />
  </IconButton>
);
export default PrevArrow;
