/* eslint-disable react/jsx-props-no-spreading */
import { observer, inject } from 'mobx-react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import FormSpher1 from '../components/MainComponents/FormSpher/FormSpher copy';
import '../assets/styles/Components/formSpheroid.scss';

import CanvasStl from '../components/3D/CanvasStl';

const FormPage = ({ spherStore }) => {
  const [fileStl, setFileStl] = useState();
  const onDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileStl(reader.result);
      spherStore.setFileStl(acceptedFiles[0]);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    acept: '.stl',
  });
  return (
    <div className='wrapper'>
      {!fileStl ? (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          sx={{ border: '1px dashed grey' }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <CloudDownloadIcon color='disabled' sx={{ fontSize: 150 }} />
          <Typography variant='h4'>
            Click to select file or drag-and-drop the stl file here!!
          </Typography>
        </Box>
      ) : (
        <CanvasStl url={fileStl} />
      )}
      <FormSpher1
        onSubmit={spherStore.loadSpher}
        formObj={spherStore.formObj}
      />
    </div>
  );
};
export default inject(['spherStore'])(observer(FormPage));
