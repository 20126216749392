import {
  Breadcrumbs,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useState } from 'react';
import Help from '../../../assets/svg/Help.svg';
import Logo from '../../Logo/Logo';
import styles from '../../../assets/styles/Components/mainPage.module.scss';
import ModallAbout from '../ModalAbout/ModalAbout';

// eslint-disable-next-line react/jsx-props-no-spreading
const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

function toTitleCase(str) {
  return str.replace(
    /\b\w+/g,
    (s) => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
  );
}

const Header = ({ className, location }) => {
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [helpOpen, setHelpOpen] = useState(false);

  const handleHelpOpen = () => {
    setHelpOpen(true);
  };

  const handleHelpClose = () => {
    setHelpOpen(false);
  };

  return (
    <header className={className}>
      <Stack
        direction='row'
        spacing={2}
        justifyContent='center'
        alignItems='center'
      >
        <LinkRouter className={styles.logo} to='/'>
          <Logo width='40' />
          <p className={styles.logoText}>Viabio</p>
        </LinkRouter>
      </Stack>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize='small' />}
        aria-label='breadcrumb'
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        <LinkRouter
          underline='hover'
          color='inherit'
          component={RouterLink}
          to='/app'
        >
          Home
        </LinkRouter>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          return last ? (
            <Typography color='textPrimary' key={to}>
              {toTitleCase(value)}
            </Typography>
          ) : (
            <LinkRouter
              underline='hover'
              color='inherit'
              component={RouterLink}
              to={to}
              key={to}
            >
              {toTitleCase(value)}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
      <Stack direction='row' spacing={2}>
        <Button
          endIcon={<img src={Help} alt='Help' />}
          onClick={handleHelpOpen}
        >
          Help
        </Button>
        <LinkRouter to='/app'>
          <IconButton>
            <HomeIcon />
          </IconButton>
        </LinkRouter>
      </Stack>
      <ModallAbout isOpen={helpOpen} handleClose={handleHelpClose} />
    </header>
  );
};
export default Header;
