import { useRef } from 'react';
import * as THREE from 'three';

function getColor(value, minValue, maxValue) {
  const hue = Math.floor(
    (((value[0] + value[1] + value[2] + value[3]) / 4 - minValue) /
      (maxValue - minValue)) *
      180 +
      180
  ).toString(10);

  return ['hsl(', hue, ',100%,50%)'].join('');
}

const Tetr = ({ points, color, minValue, maxValue }) => {
  const faces = [2, 1, 0, 0, 3, 2, 2, 3, 1, 1, 0, 3];

  const normals = new Float32Array([
    points[1][0] - points[2][0],
    points[1][1] - points[2][1],
    points[1][2] - points[2][2],

    points[0][0] - points[1][0],
    points[0][1] - points[1][1],
    points[0][2] - points[1][2],

    points[2][0] - points[0][0],
    points[2][1] - points[0][1],
    points[2][2] - points[0][2],

    points[3][0] - points[0][0],
    points[3][1] - points[0][1],
    points[3][2] - points[0][2],

    points[2][0] - points[3][0],
    points[2][1] - points[3][1],
    points[2][2] - points[3][2],

    points[0][0] - points[2][0],
    points[0][1] - points[2][1],
    points[0][2] - points[2][2],

    points[3][0] - points[2][0],
    points[3][1] - points[2][1],
    points[3][2] - points[2][2],

    points[1][0] - points[3][0],
    points[1][1] - points[3][1],
    points[1][2] - points[3][2],

    points[2][0] - points[1][0],
    points[2][1] - points[1][1],
    points[2][2] - points[1][2],

    points[0][0] - points[1][0],
    points[0][1] - points[1][1],
    points[0][2] - points[1][2],

    points[3][0] - points[0][0],
    points[3][1] - points[0][1],
    points[3][2] - points[0][2],

    points[1][0] - points[3][0],
    points[1][1] - points[3][1],
    points[1][2] - points[3][2],
  ]);
  const positions = new Float32Array(points.flat());
  const indices = new Uint16Array(faces);

  const ref = useRef();

  return (
    <mesh ref={ref}>
      <bufferGeometry>
        <bufferAttribute
          attach='attributes-position'
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach='attributes-normal'
          array={normals}
          count={normals.length / 3}
          itemSize={3}
        />
        <bufferAttribute
          attach='index'
          array={indices}
          count={indices.length}
          itemSize={1}
        />
      </bufferGeometry>
      <meshPhongMaterial
        attach='material'
        color={getColor(color, minValue, maxValue)}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Tetr;
