/* eslint-disable react/jsx-props-no-spreading */
import { Typography } from '@mui/material';
import * as classnames from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import teamStyles from '../../../assets/styles/Components/team.module.scss';
import styles from '../../../assets/styles/Components/lending.module.scss';
import CardTeam from './CardTeam';
import employers from './employers';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';

function Team() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    appendDots: (dots) => (
      <div
        style={{
          borderRadius: '10px',
          padding: '10px',
        }}
      >
        <ul style={{ color: 'white', margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <div
        className='dots_custom'
        style={{
          margin: '10px',
          width: '5px',
          height: '5px',
          opacity: '0.5',
          borderRadius: '50%',
          backgroundColor: 'white',
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div>
      <h2 className={classnames(styles.h2_center, styles.h2)}>Meet Our Team</h2>
      <Typography variant='h4' component='p' className={teamStyles.para}>
        The smartest people in 3D bioprinting.
      </Typography>
      <Typography variant='h4' component='p' className={teamStyles.para}>
        We work 24x7 to provide the best service and make our partners happy.
      </Typography>
      <div className={teamStyles.slider__container}>
        <Slider {...settings} styles={{ display: 'flex' }}>
          {employers.map((employer) => (
            <CardTeam
              key={employer.name}
              employer={employer}
              isPasko={employer.name.includes('PASKO') ? 1 : 0}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Team;
