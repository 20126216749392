import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { OrbitControls } from '@react-three/drei';
import ModelStl from './ModelStl';

const CanvasStl = ({ url }) =>
  url ? (
    <Canvas>
      <Suspense fallback={null}>
        <ModelStl url={url} />
        <OrbitControls />
      </Suspense>
    </Canvas>
  ) : (
    <></>
  );
export default CanvasStl;
