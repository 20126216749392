import Linkedin from '@mui/icons-material/LinkedIn';
import Email from '@mui/icons-material/EmailRounded';
import { IconButton } from '@mui/material';
import teamStyles from '../../../assets/styles/Components/team.module.scss';

const CardTeam = ({ employer }) => (
  <div className={teamStyles.card}>
    <img
      className={teamStyles.card__photo}
      src={employer.photo}
      alt='participant'
    />

    <p className={teamStyles.name}>{employer.name}</p>
    <div>
      <IconButton href={employer.link} color='primary'>
        <Linkedin />
      </IconButton>
      <IconButton href={`mailto:${employer.mail}`} color='primary'>
        <Email />
      </IconButton>
    </div>
    <p className={teamStyles.text}>{employer.position}</p>
  </div>
);
export default CardTeam;
