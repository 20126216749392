import { ThemeProvider } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import theme from '../assets/js/theme';
import LoadPage from './LoadPage';

import LendingPage from './LendingPage';
import ListIds from './ListIds';
import MainPage from './MainPage';
import MessagePage from './MessagePage';
import StartPage from './StartPage';
import ResponseIdPage from './ResponseIdPage';
import Team from '../components/Lending/Team/Team';

function App() {
  return (
    <ThemeProvider theme={theme}>
  
      <Routes>
        <Route index path='' element={<LendingPage />} />

        <Route path='app' element={<MainPage />}>
          <Route index element={<StartPage />} />
          <Route path='start' element={<MessagePage id='1155' />} />
          <Route path='responses' element={<ListIds />} />
          <Route path='responses/:id' element={<ResponseIdPage />} />
          <Route path='load' element={<LoadPage />} />
          <Route path='team' element={<Team />} />
        </Route>
      </Routes>
    
    </ThemeProvider>
  );
}

export default App;
