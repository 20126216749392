import { observer, inject } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ResultComponent from '../components/MainComponents/3DResult/ResultComponent';
import MessagePage from './MessagePage';

const ResponseIdPage = ({ spherStore }) => {
  const params = useParams();
  useEffect(() => {
    spherStore.getSpher(params.id);
  }, []);
  if (spherStore.isResult) {
    return <ResultComponent />;
  }
  return <MessagePage id={params.id} />;
};
export default inject(['spherStore'])(observer(ResponseIdPage));
