import { Button, Stack } from '@mui/material';

import Animated3D from '../components/3D/Animated3D';
import styles from '../assets/styles/Components/messagePage.module.scss';

const StartPage = () => {
  const handleClick = () => {
    window.location.href = 'https://forms.gle/R3NkhPQjJ1ozRSuX7';
  };

  return (
    <Stack spacing={2} justifyContent='center'>
      <div className={styles.canvas}>
        <Animated3D />
      </div>

      <Button variant='contained' onClick={handleClick}>
        Load STL and set bioprinting parameters
      </Button>
    </Stack>
  );
};

export default StartPage;
